import React from "react"
import Layout from "../components/layout"
import { Link } from "gatsby"
import Container from "react-bootstrap/Container"
import { GiAbstract046 } from "react-icons/gi"
import { BsArrowRight } from "react-icons/bs"
import Seo from "../components/Seo/seo"
export default function FileNotFound() {
  return (
    <Layout>
      <Seo
        title="Page not found"
        description="The page you were looking for is not here - go back to LatinSalsaTours.com"
      />
      <Container className="text-center" style={{ minHeight: "50vH" }}>
        <div className="display-1 lstitle">
          4<GiAbstract046 />4
        </div>
        <h1 className="display-2 text-uppercase">Something went wrong</h1>
        <p className="display-6">We could not find the requested page</p>
        <Link to="/" className="display-3">
          go back to start <BsArrowRight />
        </Link>
      </Container>
    </Layout>
  )
}
